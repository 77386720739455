import { Contact } from './contact.model';

export interface ContactState {
  contacts: Contact[];
}

export const emptyContact: Contact = {
  companyId: null,
  originId: null,
  type: '',
  name: '',
  countryCode: '',
  phone: '',
  phone2: '',
  email: '',
};

export const initialContactState: ContactState = {
  contacts: []
};
